import React, { useState, useEffect } from "react";

function NavBar() {
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem("darkMode");
    return savedMode ? JSON.parse(savedMode) : true;
  });

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark-mode");
      document.body.classList.remove("light-mode");
    } else {
      document.body.classList.remove("dark-mode");
      document.body.classList.add("light-mode");
    }

    // Save to localStorage
    localStorage.setItem("darkMode", JSON.stringify(darkMode));

    // Emit a custom event to notify about the change
    const event = new CustomEvent("darkModeChange", { detail: darkMode });
    window.dispatchEvent(event);
  }, [darkMode]);

  return (
    <nav className="navbar bg-body-tertiary py-3">
      <div className="container d-flex justify-content-between align-items-center">
        <button
          className={`btn ${darkMode ? "btn-light" : "btn-dark"}`}
          onClick={toggleDarkMode}
        >
          <img
            src={darkMode ? "/light.jpg" : "/puzzle.png"}
            alt={darkMode ? "Light Mode" : "Dark Mode"}
            style={{ width: "24px", height: "24px" }}
          />
        </button>
      </div>
    </nav>
  );
}

export default NavBar;
