import React, { useEffect, useState } from "react";
import NavBar from "./components/NavBar";
import MarketTable from "./components/MarketTable";

import "./App.css";

function App() {
	const [counter, setCounter] = useState(300);
	const [marketData, setMarketData] = useState([]);
	const [hotData, setHotData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		document.title = "PolyVol";
	}, []);

	const fetchNewData = async () => {
		setLoading(true);

		try {
			// Fetch markets data
			const marketResponse = await fetch("https://api.polyvol.info/api/markets");
			if (!marketResponse.ok) throw new Error("Failed to fetch market data");
			const marketResult = await marketResponse.json();
			setMarketData(marketResult);

			// Fetch hot data
			const hotResponse = await fetch("https://api.polyvol.info/api/markets");
			if (!hotResponse.ok) throw new Error("Failed to fetch hot data");
			const hotResult = await hotResponse.json();
			setHotData(hotResult);

			setCounter(300); // Reset counter
		} catch (error) {
			console.error("Error fetching data:", error);
			setError(error.message || "An error occurred");
		} finally {
			setLoading(false);
		}
	};


	useEffect(() => {
		fetchNewData(); // Initial fetch
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			setCounter((prev) => prev - 1);
		}, 1000);

		if (counter === 0) {
			fetchNewData();
		}

		return () => clearInterval(interval);
	}, [counter]);

	return (
		<div className="App">
			<NavBar />

			<div className="container mb-5">
				{error && (
					<div className="alert alert-danger text-center mt-3">
						{error}
					</div>
				)}

				{loading ? (
					<div className="text-center mt-5">
						<div className="spinner-border text-success" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
						<p className="mt-3">Loading data...</p>
					</div>
				) : (
					<div className="row">
						<div className="col">
							<div className="row mt-4 mb-4">
								<div className="d-flex align-items-center labels-div justify-content-end col-md-12">
									<h6 className="me-5 txt-light">
										Active Markets{" "}
										<span className="text-success txt-green">{marketData.length}</span>
									</h6>
									<h6 className="txt-light">
										Next Update{" "}
										<span className="text-success txt-green">
											{Math.floor(counter / 60)}:{String(counter % 60).padStart(2, "0")}
										</span>
									</h6>
								</div>

								<div className="col-md-12 banner my-4">
									<div className="text-center">
										<h1 className="mb-4 txt-green d-flex align-items-center justify-content-center">
											<img width="100" src="/volcano.png" alt="" />
											<span>PolyVol.info </span>
										</h1>
										<h2 className="txt-green"> What’s moving now on Polymarket</h2>
									</div>
								</div>
							</div>

							<div>
								<p className="txt-green">🚀 Biggest moves since last check</p>
							</div>

							{/* Custom Scrolling Marquee */}
							<div className="marquee-container">
								<marquee behavior="scroll" direction="left" scrollamount="6">
									{hotData.map((obj, index) => (
										<a
											key={index}
											style={{ color: "#000", textDecoration: "none" }}
											href={`https://polymarket.com/event/${obj.slg}`}
											target="_blank"
											rel="noopener noreferrer"
										>
											<span className="marquee-item txt-green px-3">
												<span>Market: </span> {obj.question} <span className="px-0"></span> {`⬆️`}{obj.hot}%
											</span>
										</a>
									))}
								</marquee>
							</div>

							<div className="market-tabl">
								<MarketTable markets={marketData} />
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default App;
